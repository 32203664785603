import { useUserShowSelf } from "@gocardless/api/dashboard/user";
import { useEffect } from "react";
import { Error, getErrorsFromErrorResponse } from "@gocardless/api/utils/error";

import { Route, routerPush } from "src/common/routing";

const TERMS_NOT_ACCEPTED_REASON = "terms_and_conditions_not_accepted";

export const useTermsAccepted = () => {
  const { error } = useUserShowSelf();

  const isTermsNotAccepted = (errors: Error[]) =>
    errors.some((err) => err.reason === TERMS_NOT_ACCEPTED_REASON);

  useEffect(() => {
    if (error?.response?.status === 403) {
      getErrorsFromErrorResponse(error).then((errors) => {
        if (isTermsNotAccepted(errors)) {
          routerPush({ route: Route.AcceptTerms });
        }
      });
    }
  }, [error]);
};
