/* eslint-disable-next-line no-restricted-imports */
import {
  Box,
  Button,
  ButtonVariant,
  ColorPreset,
  JustifyContent,
  Text,
  Tag,
  TagVariant,
  TagColor,
  Icon,
  PlainLink,
  Glyph,
  PlainButton,
  Space,
} from "@gocardless/flux-react";

import { getRouteURL, Route } from "../common/routing";
import { useImpersonateOrganisation } from "../common/authorisation";
import { useOrganisation } from "../libraries/organisation/hooks";

const ImpersonationBanner = () => {
  const {
    isLoading,
    impersonateOrganisation,
    stopImpersonating,
    isImpersonating,
    organisationToImpersonate,
  } = useImpersonateOrganisation();
  const organisation = useOrganisation();

  if (isLoading || organisation === undefined) {
    return null;
  }

  return (
    <Box
      layout="flex"
      gutterH={1}
      gutterV={1}
      bg={ColorPreset.BackgroundLight_02}
      justifyContent={JustifyContent.SpaceBetween}
    >
      <Box flexDirection="row">
        <Space layout="inline" h={1} />
        <PlainLink href={getRouteURL({ route: Route.Admin, absolute: true })}>
          <Icon name={Glyph.Fast} size="16px" />
        </PlainLink>
      </Box>
      <Box css={{ margin: "auto" }}>
        Viewing as{" "}
        <PlainLink href="#" textDecoration="underline">
          <Text weight={600}>{organisation.name}</Text>
        </PlainLink>
        {organisationToImpersonate && (
          <>
            {" "}
            <Button
              variant={ButtonVariant.PrimaryOnLight}
              onClick={impersonateOrganisation}
            >
              (View as {organisationToImpersonate.organisations?.name})
            </Button>
          </>
        )}
        {isImpersonating && (
          <>
            {" "}
            <Tag variant={TagVariant.Solid} color={TagColor.Alert}>
              live account!
            </Tag>
          </>
        )}
      </Box>
      <Box flexDirection="row">
        <PlainLink
          href="https://spoc.gocardless.io/product_and_engineering"
          target="_blank"
        >
          <Icon name={Glyph.ExclamationCircle} size="16px" />
        </PlainLink>

        {isImpersonating && (
          <>
            <Space layout="inline" h={4} />
            <PlainButton
              onClick={stopImpersonating}
              data-testid="stop-impersonating"
            >
              <Icon name={Glyph.Close} />
            </PlainButton>
          </>
        )}

        <Space layout="inline" h={1} />
      </Box>
    </Box>
  );
};

export default ImpersonationBanner;
