import { useState } from "react";
import { CreditorResource } from "@gocardless/api/dashboard/types";

import { loadZendeskScript } from "./client/zendesk";

import { getConfig } from "src/common/config";

export const useLoadZendesk = () => {
  const [isZendeskLoaded, setZendeskLoaded] = useState(false);

  // Modified loadZendesk function to include criteria checks
  const loadZendesk = async (creditor: CreditorResource | undefined) => {
    const zendeskAppId = getConfig().client.zendesk.appId;
    const jwt_secret = getConfig().client.zendesk.jwt_secret;
    const jwt_id = getConfig().client.zendesk.jwt_id;

    if (!window || !creditor || !zendeskAppId) {
      return;
    }
    setZendeskLoaded(true);
    loadZendeskScript(zendeskAppId, { jwt_secret, jwt_id });
  };

  return {
    loadZendesk,
    isZendeskLoaded,
  };
};
