import { useMultiAccountFeature } from "../hooks/useMultiAccountFeature";

export const MultiAccountRestriction: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { hasMultiAccountFeature } = useMultiAccountFeature();

  if (!hasMultiAccountFeature) return null;

  return <>{children}</>;
};
