import { useEffect } from "react";
import { CreditorResource } from "@gocardless/api/dashboard/types";

import { useLoadZendesk } from "src/technical-integrations/zendesk/useLoadZendesk";

declare global {
  interface Window {
    Zendesk: (action: string) => void | boolean;
    zendeskSettings?: {
      app_id: string;
      name: string;
      email: string;
      user_hash: string;
      user_id: string;
      language_override: string;
      hide_default_launcher?: boolean;
    };
  }
}

interface ZendeskProps {
  children: React.ReactNode;
  creditor: CreditorResource | undefined;
}

const Zendesk: React.FC<ZendeskProps> = (props) => {
  const { loadZendesk } = useLoadZendesk();
  useEffect(() => {
    if (props?.creditor) {
      loadZendesk(props?.creditor);
    }
  }, [loadZendesk, props?.creditor]);

  return <></>;
};

export default Zendesk;
