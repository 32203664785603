import { Box, JustifyContent, AlignItems } from "@gocardless/flux-react";

import GCLogo from "../../assets/svg/gocardless-symbol-dawn.svg";

import SpinnerWithImage from "src/components/shared/SpinnerWithImage";

export const LoadingPage = ({
  showSpinner = true,
}: {
  showSpinner?: boolean;
}) => (
  <Box height="100vh" css={{ position: "relative" }}>
    {showSpinner && (
      <Box
        layout="flex"
        justifyContent={JustifyContent.Center}
        alignItems={AlignItems.Center}
        height="100%"
        width="100%"
        css={{ position: "absolute" }}
      >
        <SpinnerWithImage imageSize="lrg" imageSource={GCLogo} />
      </Box>
    )}
  </Box>
);
