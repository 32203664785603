import { useOrganisationProposedLink } from "@gocardless/api/dashboard/organisation";

import { useMultiAccountFeature } from "src/components/routes/Accounts/hooks/useMultiAccountFeature";

export const useProposedAccountLinks = () => {
  const { hasMultiAccountFeature, isLoading: multiAccountFeatureLoading } =
    useMultiAccountFeature();

  const {
    data,
    isLoading: proposedLinksLoading,
    mutate,
  } = useOrganisationProposedLink(hasMultiAccountFeature);

  return {
    proposedAccountLinks: hasMultiAccountFeature
      ? data?.organisations ?? []
      : [],
    isLoading: multiAccountFeatureLoading || proposedLinksLoading,
    refetchProposedAccountLinks: mutate,
  };
};
